import React from 'react';
import $ from "jquery";

export default function getInTouch() {
  const handleSumbit = (e) => {
    e.preventDefault();
    
    const form = $(e.target);
    const sent = () => {
      document.getElementById('subscriptionAlert').innerHTML = "Thanks! We've sent you an email so you can confirm your subscription";
      document.getElementById('subsMail').value = '';
    };
    $.ajax({
        type: "POST",
        url: form.attr("action"),
        data: form.serialize(),
        success: sent(),
    });
    setInterval(function () {document.getElementById('subscriptionAlert').style.display = "none"}, 6000);
  };
  return (
    <section id='getInTouch'>
        <div className='getInTouch container-fluid text-center r3'>
                <div className='t2 colorWhite'>Always be up to date with new events</div>
                <div className='t3 colorWhite'>
                Join our mailing list to receive news and announcements
                </div>
                <form className='mt-3' method='post' onSubmit={(e) => handleSumbit(e)} action="subs.php">
                    <input className='potoInput getInTouchInput' placeholder='yourmail@example.com' type='text' name='email' id='subsMail' required/>
                    <button className='btn colorWhite potoBtn getInTouchBtn' type='submit'>SUBMIT</button>
                    <div role="alert" id="subscriptionAlert"></div>
                </form>
            </div>
    </section>
  )
}
