import React from "react";
import GetProto from "./getProto.js";
import {useParams} from "react-router-dom";
import SecComp from "./sec&com.js";
import Dev from "./development.js";


export default function ServicesMore(props) {
    let head, path, dHead, dSubHead, dpara, con;
    try {
        const useidObj = useParams();
    (() => {
        if(useidObj.id === "security" || useidObj.id === "compliance"){
            head='Security & Compliance';
            path=head;
            dHead="Worried about Data Privacy and Security?";
            dSubHead='Don’t worry, thePro.dev has got you covered. You\'ve come to the right place';
            dpara="We offer more than 10 unique services, specially tailored to meet your needs. Whether it is to protect your website from security threats or meet the strict compliance requirements, our team is ready to handle it all! Each of our services is created to serve a specific purpose, and each one is maintained with great care and constant monitoring.";
            con = <SecComp/>;
        }
        else if(useidObj.id === "development" || useidObj.id === "testing"){
            head='Development & Testing';
            path=head;
            dHead="Hey, looking for someone to turn your vision into reality?";
            dSubHead='Don’t worry, thePro.dev has got you covered.';
            dpara="Or are you paranoid about your application's performance and don't know where to start?";
            con = <Dev/>;
        }
        else if(useidObj.id === "maintanance" || useidObj.id === "seo" || useidObj.id === "allinone"){
            head='All In One';
            path=head;
            dHead="Want to bring your business online?";
            dSubHead='But you don\'t know where to start...';
            dpara="We offer a unique solution that eliminates all the troublesome work and sets your mind at ease! We give you the tools to create a full-featured, custom online website at the click of a button.";
            con = null;
        }
        else{
            window.location = "/404";
        }
    }) ();
    } catch (error) {
        
    }
    return(
        <>
        <section id='servicesMore'>
            <div className='titleDiv'>
                <div className='overlay'>
                    <div className='textTitle'>
                    <span>|</span>
                    <h2>{head}</h2>
                    <p>Home / {path}</p>
                    </div>
                </div>
            </div>
            <div className="d-flex subDiv w-100 bgdark padding0">
                <div className="serivesMSec1 letter">
                    <h3>{dHead}</h3>
                    <h4>{dSubHead}</h4>
                    <p>{dpara}</p>
                    {con}
                </div>
                <div className="serivesMSec2 d-none"></div>
            </div>
        </section>
        <GetProto head="Still Confused?" para="Get a free Consultation for your organization now! Send your requirements, we will analyse and  create a quote for you!"/>
        </>
    );
}