import React from 'react'

export default function footer(props) {
    const displayStyle = props.tel ? 'block' : 'none';
    return (
        <footer>
            <div className='container'>
                <h1 className='aboutText'>
                    {props.name}
                </h1>
                <hr />
                <p>{props.subhead}</p>
                <ul className='contactList'>
                    <li style={{ display: displayStyle }}><a href={'tel:' + props.tel}>{props.tel}</a></li>
                    <li><a href={'mailto:' + props.mail1}>{props.mail1}</a></li>
                    <li><a href={'mailto:' + props.mail2}>{props.mail2}</a></li>
                </ul>
            </div>
            <div className='copyrightText'>
                <span className='container'>
                    Copyright © 2022 All Rights Reserved. Developed By THEPRO.DEV
                </span>
            </div>
        </footer>
    )
}
