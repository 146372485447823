import { useState } from 'react';

function SlidingTestimonials({ users }) {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleNextClick = () => {
    setActiveIndex((activeIndex + 1) % users.length);
  };

  const handlePrevClick = () => {
    setActiveIndex((activeIndex + users.length - 1) % users.length);
  };

  // Function to update the active index every 5 seconds
  const slideInterval = setInterval(() => {
    setActiveIndex((activeIndex + 1) % users.length);
  }, 10000);

  const testimonial = users[activeIndex];

  return (
    <div className="sliding-testimonials">
      <div className="testimonial">
        <img src={testimonial.image} alt={testimonial.name} />
        <div className="testimonial-content">
          <p>{testimonial.testimonial}</p>
          <h4>{testimonial.name}</h4>
          <span>{testimonial.occupation}</span>
        </div>
      </div>
      <div className="controls">
        <button onClick={handlePrevClick}>Prev</button>
        <button onClick={handleNextClick}>Next</button>
      </div>
    </div>
  );
}

export default SlidingTestimonials;


