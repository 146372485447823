import React from 'react';
import { Link } from "react-router-dom";
import ReachedNumbers from './counter';

// .scrollIntoView({block: "end"});

export default function whovr() {
    return (
        <section className='whovrSec mt60' id='aboutUs'>
            <div className='container text-center r3'>
                <div className="t1">
                    <span className='sqT'>&#9632;</span>
                    who  we  are
                    <span className='sqT'>&#9632;</span>
                </div>
                <div className="t2">One Stop For All Your Needs.</div>
                <div className="t3">
                    Our web design company specializes in the professional creation of unique sites. Our team constantly monitors the emergence of new technologies that we are not afraid to implement in web projects, making them modern and high-tech. We create only selling websites – this is an achievement by drawing the design and working out usability.
                </div>
                <Link to="services/maintanance">
                    <button className='btn mt-4'>Know More</button>
                </Link>
            </div>
            <div className="d-flex lItem">
                <div className='item'>
                    <div className='countText'>
                        <p className="m-0"><ReachedNumbers n={20} /><sup>+</sup></p>
                        <p className='subCText'>Satisfied clients</p>
                    </div>
                </div>
                <div className='item'>
                    <div className='countText'>
                        <p className="m-0"><ReachedNumbers n={10} /><sup>+</sup></p>
                        <p className='subCText'>Team members</p>
                    </div>
                </div>
                <div className='item'>
                    <div className='countText'>
                        <p className="m-0"><ReachedNumbers n={60} /><sup>+</sup></p>
                        <p className='subCText'>Project delivered</p>
                    </div>
                </div>
                <div className='item'>
                    <div className='countText'>
                        <p className="m-0"><ReachedNumbers n={3} /><sup>+</sup></p>
                        <p className='subCText'>Years of experience</p>
                    </div>
                </div>
            </div>
        </section>
    )
}
