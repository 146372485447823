import React from "react";
import { Link } from "react-router-dom";

export default function Main(props) {
  return (
    <div className="container-fluid mainComp mt-5">
      <div className="sec1">
        <h1>
          {props.header}
          <span className="underS">_</span>
        </h1>
        <p>{props.subhead}</p>
        <ul className="checkedItems">
          <li>{props.li1}</li>
          <li>{props.li2}</li>
          <li>{props.li3}</li>
        </ul>
        <Link to="services">
        <button type="button" className="getstartedBtn">
          {props.btnValue}
        </button>
        </Link>
      </div>
      <div className="sec2"></div>
    </div>
  );
}
