import React from "react";
import { Link } from "react-router-dom";

const cardStyle = {
  width: "5rem; !important",
};

// document.getElementById('customRange3').onchange = () => {
//   document.getElementById('outVal').innerText = "Est. " + document.getElementById('customRange3').value*4 +".00 INR for " + document.getElementById('customRange3').value + " Data";
//   document.getElementById('customRange3').style.background = 'linear-gradient(90deg,  #65b8068e '+document.getElementById('customRange3').value+'%, #ddd 0%)';
// }

export default function ourServices() {
  return (
    <section id="ourServices" className="mb70 mt60">
      <div className="ourServices container-fluid text-center r3">
        <div className="t1">
          <span className="sqT">&#9632;</span>
          WHAT WE OFFER
          <span className="sqT">&#9632;</span>
        </div>
        <div className="t2">Our services</div>
        <div className="t3">
          We offer professional services at affordable rates to help your
          business attract more visitors and keep them on your site!
        </div>
      </div>
      <div className="servicesList d-flex justify-content-aroun">
        <Link to="services/security" className="serviceCard">
        <div className="text-center hoverBlack" style={cardStyle}>
          <div className="scardImg mb-4">
            <div id="securityIco"></div>
          </div>
          <div className="cardBody mb-4">
            <h1
              className="cardTitle"
              keywords="Network (Infrastructure) Penetration Testing, Wordpress Security Audit, API Security Assessment, IOT penetration testing, Social Engineering Assessment, Nessus/Burp Suite Professional Scan, Threat Modeling, OSINT Assesment, Security Consultation"
            >
              Cybersecurity
            </h1>
            <p>
              Black Box Penetration Testing, White Box Penetration, Network
              Penetration Testing
            </p>
          </div>
          <span className="vmBottom">
            <span>
              Learn More &nbsp; &nbsp;<button>&rarr;</button>
            </span>
          </span>
        </div>
        </Link>
        <Link to="services/development" className="serviceCard">
        <div className="text-center hoverBlack" style={cardStyle}>
          <div className="scardImg mb-4">
            <div id="developmentIco"></div>
          </div>
          <div className="cardBody mb-4">
            <h1
              className="cardTitle"
              keywords="Content Delivery Network (CDN), E-commerce, Billing Software, Designing, Power App Development"
            >
              Development
            </h1>
            <p>
              Software Development, Web Application, Mobile Application, CDN,
              E-commerce
            </p>
          </div>
          <span className="vmBottom">
            <span>
              Learn More &nbsp; &nbsp;<button>&rarr;</button>
            </span>
          </span>
        </div>
        </Link>
        <Link to="services/security" className="serviceCard">
        <div className="text-center hoverBlack" style={cardStyle}>
          <div className="scardImg mb-4">
            <div id="complianceIco"></div>
          </div>
          <div className="cardBody mb-4">
            <h1 className="cardTitle">Compliance</h1>
            <p>
              GDPR Compliance, Cookie Compliance, PCI Data Security Standard
              (PCI DSS) , Privacy Audit
            </p>
          </div>
          <span className="vmBottom">
            <span>
              Learn More &nbsp; &nbsp;<button>&rarr;</button>
            </span>
          </span>
        </div>
        </Link>
        <Link to="services/testing" className="serviceCard">
        <div className="text-center hoverBlack" style={cardStyle}>
          <div className="scardImg mb-4">
            <div id="testingIco"></div>
          </div>
          <div className="cardBody mb-4">
            <h1
              className="cardTitle"
              keywords="Mobile Testing, API testing, Database Testing"
            >
              Testing
            </h1>
            <p>
              Manual Testing, Automation Testing, Performance Testing, UI
              testing
            </p>
          </div>
          <span className="vmBottom">
            <span>
              Learn More &nbsp; &nbsp;<button>&rarr;</button>
            </span>
          </span>
        </div>
        </Link>
        <Link to="services/seo" className="serviceCard">
        <div className="text-center hoverBlack" style={cardStyle}>
          <div className="scardImg mb-4">
            <div id="marketingIco"></div>
          </div>
          <div className="cardBody mb-4">
            <h1
              className="cardTitle"
              keywords="WhatsApp Bulk Messages, SMS Bulk Messages, Email Bulk Messages"
            >
              Marketing & SEO
            </h1>
            <p>
              Social Media Marketing (SMM), Search Engine Optimization (SEO),
              Data Collection
            </p>
          </div>
          <span className="vmBottom">
            <span>
              Learn More &nbsp; &nbsp;<button>&rarr;</button>
            </span>
          </span>
        </div>
        </Link>
        <Link to="services/maintanance" className="serviceCard">
        <div className="text-center hoverBlack" style={cardStyle}>
          <div className="scardImg mb-4">
            <div id="allinoneIco"></div>
          </div>
          <div className="cardBody mb-4">
            <h1 className="cardTitle">All In One</h1>
            <p>Web Hosting, Website Maintainance, Professional Email service</p>
          </div>
          <span className="vmBottom">
            <span>
              Learn More &nbsp; &nbsp;<button>&rarr;</button>
            </span>
          </span>
        </div>
        </Link>

        {/* <!-- Button trigger modal -->
        <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
          Launch demo modal
        </button>

        <!-- Modal --> 
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="getProto container-fluid text-center r3">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
                <div className="t1">
                  <span className="sqT">&#9632;</span>
                  Get Your Data
                  <span className="sqT">&#9632;</span>
                </div>
                <div className="t2 colorWhite">
                  Get your personalized data for your business!
                </div>
                <div className="t3 colorWhite">
                  Send your requirements, we will research on it, create a
                  tailor-made data and send the result to you!
                </div>
                <form>
                  <input
                    className="potoInput"
                    id="nameInput"
                    placeholder="Full Name"
                    type="text"
                  />
                  <input
                    className="potoInput"
                    placeholder="+91 00000 00000"
                    type="mobile"
                  />
                  <select
                    className="potoInput"
                    aria-label="Default select example"
                  >
                    <option defaultValue="">Select Your Data</option>
                    <option value="1">Mobile Number</option>
                    <option value="2">Email</option>
                    <option value="3">Other (Specify In Messages)</option>
                  </select>
                  <textarea
                    className="potoInput"
                    placeholder="Message [If Any]"
                    type="text"
                  ></textarea>
                  <label
                    htmlFor="customRange3"
                    className="form-label textWhite"
                    id="numData"
                  >
                    Number of data
                  </label>
                  <input
                    type="range"
                    className="range"
                    min="100"
                    max="10000"
                    step="100"
                    id="customRange3"
                    onInput={() => {
                      document.getElementById("outVal").innerText =
                        "Est. " +
                        document.getElementById("customRange3").value * 4 +
                        ".00 INR for " +
                        document.getElementById("customRange3").value +
                        " Data";
                      document.getElementById("customRange3").style.background =
                        "linear-gradient(90deg,  #65b8068e " +
                        document.getElementById("customRange3").value / 100 +
                        "%, #ddd 0%)";
                    }}
                  />
                  <output id="outVal" className="textWhite">
                    00
                  </output>
                  <p> INR</p>
                  <button
                    className="btn mt-2 colorWhite potoBtn"
                    onClick={() =>
                      alert("Thank you! You deatails are submitted.")
                    }
                  >
                    SUBMIT
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>*/}
      </div>
    </section>
  );
}
