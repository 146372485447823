import React from "react";

const liStyle = {
  "font-size": "inherit",
  "margin-top": "20px",
};
const noBullets ={
  "list-style-type":"none"
}

export default function SecComp() {
  return (
    <p>
      <ol>
        <li style={liStyle}>
          <strong className="d-block">
            VAPT (Vulnerability Assessment & Penetration Testing) Services
          </strong>
          If you want to conduct an internal security audit on your company, or
          if you're not certain whether your website can withstand attacks and
          hacking attempts, our collection of VAPT services is the right
          solution for you.
          <ul className="pt-2 ulStyle">
            <li>Network and Infrastructure VAPT</li>
            <li>Web Application VAPT</li>
            <li>Mobile Application VAPT</li>
            <li>IOT devices VAPT</li>
            <li>Source Code (White Box) VAPT</li>
            <li>API Endpoints VAPT</li>
            <li style={noBullets}>
              <strong>Low on Budget?</strong><br/>We have made special pocket-friendly services that
              might suit your needs.
              <ul>
                
            <li>Wordpress Security Audit</li>
            <li>Automated Website Security Scan</li>
              </ul>
            </li>
          </ul>
        </li>
        <li style={liStyle}>
          <strong className="d-block">
          Privacy Compliance
          </strong>
          Easily verify if your application is compliant with the industry’s privacy regulations with our privacy focused plans.
          <ul className="pt-2 ulStyle">
            <li>GDPR Compliance</li>
            <li>PCI-DSS Compliance</li>
            <li>Cookie Privacy Audit</li>
          </ul>
        </li>
        <li style={liStyle}>
          <strong className="d-block">
          Other Services Offered
          </strong>
          Besides VAPT and Compliance audits, we also offer other targeted services
          <ul className="pt-2 ulStyle">
            <li>Threat Modelling</li>
            <li>OSINT Assessment</li>
          </ul>
        </li>
      </ol>
    </p>
  );
}
