import React from "react";

const liStyle = {
  "font-size": "inherit",
  "margin-top": "20px",
};

export default function SecComp() {
  return (
    <p>
      <ol>
        <li style={liStyle}>
          <strong className="d-block">
          Development Services
          </strong>
          We help organizations create well-designed, user-friendly, and fast applications. Whether you want to upgrade your existing project or you want to bring your business online, we have solutions to meet all your requirements. We have an established team of highly trained professionals who would design and develop web & mobile apps which will help you reach your goals in the competitive market. 
          <ul className="pt-2 ulStyle">
            <li>Software Development</li>
            <li>Web Application Development</li>
            <li>Mobile Application Development</li>
            <li>Content Delivery Network (CDN) Development</li>
            <li>E-commerce Website Development</li>
            <li>Billing Software Development</li>
            <li>Power App Development</li>
            <li>Existing Application Update</li>
            <li>Custom Request</li>
          </ul>
        </li>
        <li style={liStyle}>
          <strong className="d-block">
          Testing Services
          </strong>
          Our team of trained professionals helps you deliver quality experiences to every customer on every device across the world.
          <ul className="pt-2 ulStyle">
            <li>User Acceptance Testing</li>
            <li>QA Testing</li>
            <li>Manual Testing</li>
            <li>Automation Testing</li>
            <li>Mobile Testing</li>
            <li>Database Testing</li>
            <li>Performance Testing</li>
            <li>UI testing</li>
            <li>API testing</li>
          </ul>
        </li>
      </ol>
    </p>
  );
}
