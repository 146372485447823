import "./App.css";
import NavBar from "./components/header.js";
import Home  from "./components/home.js";
import FooterSec from "./components/footer.js";
import ServicesMore from "./components/servicesMore.js";
import Contacts from "./components/contacts.js";
import OurServices from "./components/ourServices.js";
import About from "./components/whovr.js";
import GetQuotes from "./components/getProto.js";
import Page404 from "./components/404page";

import {
  BrowserRouter as Router,
  Route,
  Routes, useLocation
} from "react-router-dom";
import { useLayoutEffect } from 'react';

const Wrapper = ({children}) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
    if(document.getElementsByClassName("active").length > 1){
      document.getElementById("homeNav").classList.remove("active");
    }
    else if (document.getElementsByClassName("active").length === 0){
      document.getElementById("homeNav").classList.add("active");
    }
    let firstLetter = document.getElementsByClassName("active")[0].innerText[0].toUpperCase();
    let restLetters = document.getElementsByClassName("active")[0].innerText.slice(1);
    let currentTitle = firstLetter + restLetters.toLowerCase();
    if(currentTitle === 'Home'){
      document.title = "thePro.dev: Cybersecurity | Development | Testing | Compliance";
    }
    else{
      document.title = "thePro.dev | " + currentTitle;
    }
  }, [location.pathname]);
  return children
} 

function App() {
  (function(){
    let mediaQueryObj = window.matchMedia('(prefers-color-scheme: dark)');
    if(mediaQueryObj.matches && localStorage.getItem("userChoice") === null){
      localStorage.setItem("isDarkMode", "true");
    }
    else if (localStorage.getItem("userChoice") === null){
      localStorage.setItem("isDarkMode", "false");
    }
    if(localStorage.getItem("isDarkMode") === 'true' )
    {  
      let metaThemeColor = document.querySelector("meta[name=theme-color]");
      metaThemeColor.setAttribute("content", "black");
      
      let r = document.querySelector(':root');
      r.style.setProperty('--textColor', '#fff');
      r.style.setProperty('--lgTextColor', '#fff');
      r.style.setProperty('--rdText', '#fff');
      r.style.setProperty('--bgColor', '#000');
      r.style.setProperty('--letterBg', '#1a1a1a');
      r.style.setProperty('--cardBg', '#1b2032');
    }
    else{
    }
  })();
  return (
    <>
    
    <Router>
      <NavBar
        item1="Home"
        item2="Services"
        item3="About Us"
        item4="Contact"
        item5="Get Quotes"
      />
      <Wrapper>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="services" element={<OurServices/>}/>
        <Route path="about" element={<About/>}/>
        <Route path="contact" element={<Contacts/>}/>
        <Route path="services/:id" element={<ServicesMore/>}/>
        <Route path="services/services/:id" element={<ServicesMore/>}/>
        <Route path="quotes" element={<GetQuotes/>}/>
        <Route path="404" element={<Page404/>} />
        <Route path="*" element={<Page404/>}/>
      </Routes>
      </Wrapper>
      <FooterSec
        name='About'
        subhead='We have much planned for the future, working with great clients and continued software development.'
        mail1='contact@thePro.dev'
        mail2='business@thePro.dev'
      />
      </Router>
    </>
  );
}

export default App;
