import WhoWeAre from "./whovr.js";
import GetProto from "./getProto.js";
import OurServices from "./ourServices.js";
import GetInTouch from "./getInTouch.js";
import Main from "./mainComp.js";

export default function Home() {
  return (
    <>
      <Main
        header="We make it, We secure it :)"
        subhead="A one step solution for everything you need."
        li1="Build your dream website"
        li2="Protect your user's data and privacy"
        li3="Check the quality of your website with our testing services"
        btnValue="Get Started"
      />
      <OurServices />
      {/* <Products/> */}
      <GetProto />

      <WhoWeAre />

      <GetInTouch />
    </>
  );
}
