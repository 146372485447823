import React from "react";
import { NavLink, Link } from "react-router-dom";

export default function Header(props) {
  const myFun = () =>{
    localStorage.setItem("userChoice", "true");
    if(document.getElementById('flexSwitchCheck').checked === true)
    {
      localStorage.setItem("isDarkMode", "true");
      window.location.reload();
    }
    else
    {
      localStorage.setItem("isDarkMode", "false");
      window.location.reload();    
    }
  }
  (function(){
    setInterval(()=>{
      if(localStorage.getItem("isDarkMode") === "true") 
    {
      document.getElementById('flexSwitchCheck').checked = true;
    }}, 10);
  })();
  return (
    <nav className="navbar navbar-expand-lg mt-0">
      <div className="container">
        <Link to="/">
          <div className="navbar-brand" href="in.html">
            <span className="MPLUS logoText">the</span>
            <span className="Bebas logoText">PRO</span>
            <sub className="Bebas">.DEV</sub>
          </div>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          {/* <span className="navicon"></span> */}
          <input type="checkbox" id="menyAvPaa" />
          <label id="burger" htmlFor="menyAvPaa">
            <div></div>
            <div></div>
            <div></div>
          </label>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav  w-100 mr-auto justify-content-center">
            <li
              className="nav-item"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              onClick={() => document.getElementById("menyAvPaa").checked = false}
            >
              <NavLink className="nav-link" id="homeNav" to="/">
                {props.item1}
              </NavLink>
            </li>
            <li
              className="nav-item"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              onClick={() => document.getElementById("menyAvPaa").checked = false}
            >
              <NavLink className="nav-link" to="/services">
                {props.item2}
              </NavLink>
            </li>
            <li
              className="nav-item dropdown"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              onClick={() => document.getElementById("menyAvPaa").checked = false}
            >
              <NavLink className="nav-link" to="/about">
                {props.item3}
              </NavLink>
            </li>
            <li
              className="nav-item"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              onClick={() => document.getElementById("menyAvPaa").checked = false}
            >
              <NavLink className="nav-link" to="/contact">
                {props.item4}
              </NavLink>
            </li>
            <li
              className="nav-item"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              onClick={() => document.getElementById("menyAvPaa").checked = false}
            >
              <NavLink className="nav-link" to="/quotes">
                {props.item5}
              </NavLink>
            </li>
          </ul>
          <div
            className="btn-group socialMBtn nav-item"
            role="group"
            aria-label="social media links"
          >
            <a
              href="https://www.upwork.com/agencies/1576618254838136832/"
              target="_blank"
              rel="noreferrer"
            >
              <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" width="22px" height="22px" className="socialBtn">
                <g id="surface363705901">
                  <path d="M 0.441406 3.960938 C 0.195312 3.960938 0 4.15625 0 4.398438 L 0 10.609375 C 0 13.320312 2.058594 15.679688 4.765625 15.832031 C 7.363281 15.976562 9.570312 14.15625 10.03125 11.726562 C 10.28125 12.066406 10.535156 12.378906 10.792969 12.667969 L 9.242188 19.257812 C 9.210938 19.390625 9.242188 19.527344 9.324219 19.632812 C 9.410156 19.738281 9.535156 19.800781 9.667969 19.800781 L 11.921875 19.800781 C 12.125 19.800781 12.300781 19.664062 12.347656 19.464844 C 12.621094 18.3125 13.050781 16.503906 13.4375 14.867188 L 13.839844 15.097656 C 14.792969 15.589844 15.757812 15.839844 16.71875 15.839844 C 19.882812 15.839844 22.40625 13.042969 21.945312 9.789062 C 21.640625 7.636719 19.980469 5.859375 17.855469 5.402344 C 15.378906 4.867188 12.992188 6.136719 11.964844 8.246094 C 11.964844 8.246094 11.851562 8.5 11.757812 8.753906 C 11.035156 7.5 10.605469 6.203125 10.382812 5.261719 C 10.300781 4.917969 10.179688 4.296875 10.167969 4.253906 C 10.105469 4.078125 9.9375 3.960938 9.75 3.960938 L 7.480469 3.960938 C 7.238281 3.960938 7.039062 4.15625 7.039062 4.398438 L 7.039062 10.691406 C 7.039062 11.726562 6.285156 12.648438 5.257812 12.75 C 4.078125 12.867188 3.078125 11.9375 3.078125 10.78125 L 3.078125 4.398438 C 3.078125 4.15625 2.882812 3.960938 2.640625 3.960938 Z M 16.71875 8.359375 C 17.933594 8.359375 18.921875 9.347656 18.921875 10.558594 C 18.921875 11.773438 17.933594 12.761719 16.71875 12.761719 C 15.742188 12.761719 14.832031 12.167969 14.199219 11.65625 C 14.429688 10.675781 14.585938 10.019531 14.589844 10.007812 C 14.839844 9.039062 15.714844 8.359375 16.71875 8.359375 Z M 16.71875 8.359375 " />
                </g>
              </svg>
            </a>
            <a
              href="https://www.instagram.com/thepro.dev/"
              target="_blank"
              rel="noreferrer"
            >
              <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px" className="socialBtn">
                <path d="M 8 3 C 5.243 3 3 5.243 3 8 L 3 16 C 3 18.757 5.243 21 8 21 L 16 21 C 18.757 21 21 18.757 21 16 L 21 8 C 21 5.243 18.757 3 16 3 L 8 3 z M 8 5 L 16 5 C 17.654 5 19 6.346 19 8 L 19 16 C 19 17.654 17.654 19 16 19 L 8 19 C 6.346 19 5 17.654 5 16 L 5 8 C 5 6.346 6.346 5 8 5 z M 17 6 A 1 1 0 0 0 16 7 A 1 1 0 0 0 17 8 A 1 1 0 0 0 18 7 A 1 1 0 0 0 17 6 z M 12 7 C 9.243 7 7 9.243 7 12 C 7 14.757 9.243 17 12 17 C 14.757 17 17 14.757 17 12 C 17 9.243 14.757 7 12 7 z M 12 9 C 13.654 9 15 10.346 15 12 C 15 13.654 13.654 15 12 15 C 10.346 15 9 13.654 9 12 C 9 10.346 10.346 9 12 9 z" />
              </svg>
            </a>
            <a
              href=" https://www.linkedin.com/company/thepro-dev/"
              target="_blank"
              rel="noreferrer"
            >
              <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="22px" height="22px" className="socialBtn">
                <path d="M9,25H4V10h5V25z M6.501,8C5.118,8,4,6.879,4,5.499S5.12,3,6.501,3C7.879,3,9,4.121,9,5.499C9,6.879,7.879,8,6.501,8z M27,25h-4.807v-7.3c0-1.741-0.033-3.98-2.499-3.98c-2.503,0-2.888,1.896-2.888,3.854V25H12V9.989h4.614v2.051h0.065 c0.642-1.18,2.211-2.424,4.551-2.424c4.87,0,5.77,3.109,5.77,7.151C27,16.767,27,25,27,25z" />
              </svg>
            </a>
            <a
              href="https://twitter.com/dev_thepro"
              target="_blank"
              rel="noreferrer"
            >
              <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="22px" height="22px" className="socialBtn">
                <path d="M28,6.937c-0.957,0.425-1.985,0.711-3.064,0.84c1.102-0.66,1.947-1.705,2.345-2.951c-1.03,0.611-2.172,1.055-3.388,1.295 c-0.973-1.037-2.359-1.685-3.893-1.685c-2.946,0-5.334,2.389-5.334,5.334c0,0.418,0.048,0.826,0.138,1.215 c-4.433-0.222-8.363-2.346-10.995-5.574C3.351,6.199,3.088,7.115,3.088,8.094c0,1.85,0.941,3.483,2.372,4.439 c-0.874-0.028-1.697-0.268-2.416-0.667c0,0.023,0,0.044,0,0.067c0,2.585,1.838,4.741,4.279,5.23 c-0.447,0.122-0.919,0.187-1.406,0.187c-0.343,0-0.678-0.034-1.003-0.095c0.679,2.119,2.649,3.662,4.983,3.705 c-1.825,1.431-4.125,2.284-6.625,2.284c-0.43,0-0.855-0.025-1.273-0.075c2.361,1.513,5.164,2.396,8.177,2.396 c9.812,0,15.176-8.128,15.176-15.177c0-0.231-0.005-0.461-0.015-0.69C26.38,8.945,27.285,8.006,28,6.937z" />
              </svg>
            </a>
          </div>
          <div className="form-check form-switch">
            <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheck" onChange={myFun}/>
          </div>
        </div>
      </div>
    </nav>
  );
}
