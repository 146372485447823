import { useState, useEffect, useRef } from 'react';

function CountingNumbers({n=100}) {
  const [count, setCount] = useState(1);
  const countRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          const interval = setInterval(() => {
            setCount((c) => (c < n ? c + 1 : c));
          }, 100);

          return () => clearInterval(interval);
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0,
      }
    );

    const currentRef = countRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [n]);

  return (
    <span ref={countRef}>{count}</span>
  );
}

export default CountingNumbers;
