import React from "react";
import GetProto from "./getProto.js";

export default function ContactComp(props) {
  return (
    <>
      <section id="servicesMore">
        <div className="titleDiv">
          <div className="overlay">
            <div className="textTitle">
              <span>|</span>
              <h2>{props.head}</h2>
              <p>Home / {props.path}</p>
            </div>
          </div>
        </div>
        <div className="d-flex subDiv">
          <div className="serivesMSec1">
            <h3>{props.dHead}</h3>
            <p>{props.dpara}</p>
            <div className="d-flex">
              <div className="contactCard">
                <div className="cardIcon">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0)">
                      <path d="M18.5156 15.2344C18.1922 15.2344 17.9297 15.4969 17.9297 15.8203C17.9297 16.1437 18.1922 16.4062 18.5156 16.4062C18.839 16.4062 19.1016 16.1437 19.1016 15.8203C19.1016 15.4969 18.839 15.2344 18.5156 15.2344Z"></path>
                      <path d="M5.98734 21.6671L0.127968 29.0499C-0.0118793 29.2259 -0.0384295 29.4665 0.059303 29.6688C0.157036 29.8714 0.362114 30 0.586876 30H29.415C29.6398 30 29.8448 29.8714 29.9426 29.6688C30.0405 29.4665 30.0138 29.2259 29.8739 29.0499L24.0145 21.6671C23.9035 21.527 23.7344 21.4453 23.5556 21.4453H18.423L22.8049 14.5724C23.8328 13.0314 24.3759 11.2342 24.3759 9.375C24.3759 4.2057 20.1705 0 15.0009 0C9.83141 0 5.62594 4.2057 5.62594 9.375C5.62594 11.2342 6.16907 13.0312 7.19698 14.5724L11.5789 21.4453H6.44625C6.26749 21.4453 6.09858 21.527 5.98734 21.6671ZM3.79969 26.3086H7.34255L5.34304 28.8281H1.79995L3.79969 26.3086ZM12.3262 22.6172L13.9325 25.1367H9.7687L11.7684 22.6172H12.3262ZM17.6759 22.6172H18.2337L20.2332 25.1367H16.0696L17.6759 22.6172ZM21.1634 26.3086L23.1629 28.8281H6.83901L8.83875 26.3086H21.1634ZM24.6591 28.8281L22.6593 26.3086H26.2024L28.2019 28.8281H24.6591ZM25.2722 25.1367H21.7294L19.7296 22.6172H23.2727L25.2722 25.1367ZM8.17522 13.927C7.27412 12.578 6.79781 11.004 6.79781 9.375C6.79781 4.85184 10.4778 1.17188 15.0009 1.17188C19.5241 1.17188 23.2041 4.85184 23.2041 9.375C23.2041 11.004 22.7278 12.578 21.8269 13.927C21.8244 13.9304 21.8223 13.9339 21.82 13.9373C21.4353 14.5409 15.2973 24.1681 15.0009 24.6327C14.2555 23.4634 8.94106 15.1279 8.18186 13.9373C8.1798 13.9339 8.17751 13.9304 8.17522 13.927ZM10.2722 22.6172L8.27273 25.1367H4.72964L6.72938 22.6172H10.2722Z"></path>
                      <path d="M15 15.2344C18.2167 15.2344 20.8594 12.6295 20.8594 9.375C20.8594 6.1441 18.2309 3.51562 15 3.51562C11.7691 3.51562 9.14062 6.1441 9.14062 9.375C9.14062 12.6304 11.7842 15.2344 15 15.2344ZM15 4.6875C17.5848 4.6875 19.6875 6.79024 19.6875 9.375C19.6875 11.9838 17.5678 14.0625 15 14.0625C12.4315 14.0625 10.3125 11.9838 10.3125 9.375C10.3125 6.79024 12.4152 4.6875 15 4.6875Z"></path>
                      <path d="M17.4683 17.2339C17.1913 17.0668 16.831 17.1561 16.664 17.4332L14.4983 21.0262C14.3312 21.3034 14.4205 21.6634 14.6976 21.8305C14.976 21.9983 15.3355 21.9072 15.5019 21.6311L17.6676 18.0382C17.8347 17.761 17.7454 17.401 17.4683 17.2339Z"></path>
                    </g>
                    <defs>
                      <clipPath>
                        <rect width="30" height="30" fill="white"></rect>
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <h4>Address</h4>
                <p>
                  C41 B Block Sector 34, 
                  <br />
                  Noida, Uttar Pradesh, 201301
                </p>
              </div>
              <div className="contactCard">
                <div className="cardIcon">
                  <svg
                    width="30"
                    height="23"
                    viewBox="0 0 30 23"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M29.4023 0H0.597597C0.267597 0 0 0.310291 0 0.693093V18.955C0 20.9097 1.37109 22.5 3.05654 22.5H26.9435C28.6289 22.5 30 20.9097 30 18.955V0.693093C29.9999 0.310291 29.7323 0 29.4023 0ZM28.8047 18.955C28.8047 20.1453 27.9698 21.1137 26.9435 21.1137H3.05654C2.03021 21.1137 1.19525 20.1452 1.19525 18.955V2.20527L14.6214 14.9529C14.7316 15.0574 14.8657 15.1097 15 15.1097C15.134 15.1097 15.268 15.0576 15.378 14.9534L25.767 5.11125C26.0226 4.86905 26.0606 4.43249 25.8518 4.136C25.6429 3.8395 25.2666 3.79546 25.0109 4.03773L15.0004 13.5213L2.21947 1.38619H28.8047V18.955Z"></path>
                  </svg>
                </div>
                <h4>Email</h4>
                <p>
                  <a href="mailto:contact@thePro.dev">contact@thePro.dev</a>
                  <br />
                  <a href="mailto:business@thePro.dev">business@thePro.dev</a>
                </p>
              </div>
              {/* Contact and Working hours */}
            </div>
          </div>
          <div className="contactMSec2"></div>
        </div>
      </section>
      <GetProto />
    </>
  );
  
}
ContactComp.defaultProps = {
  head: "Contact",
  path: "Contact",
  dHead: "Be In Touch",
  dpara: "Send your requirements for a website, we will research your specification and competitors, create a prototype of the website for free and sed a result to you! It will be something amazing!",
}
