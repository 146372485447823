import React from 'react';
import png404 from '../components/images/404.png';
import { Link } from "react-router-dom";

export default function page404(){
    return (
        <div className='c404 container'>
            <div>
                <img src={png404} alt='404'></img>
            </div>
            <div>
                <h1>404</h1>
                <h2>UH OH! You're lost :(</h2>
                <p>The page you are looking for does not exist.
                    How you got here is a mystery. But you can click the button below
                    to go back to the homepage.
                </p>
                <Link to="/"><button class="btn green">Go Home</button></Link>
            </div>
        </div>
    );
}
