import React from "react";
import $ from "jquery";

export default function getProto(props) {

  const sendEmail = (e) => {
    e.preventDefault();
    let alertBox = document.getElementById('mailAlert');
    const form = $(e.target);
    
    const sent = () => {
      alertBox.style.display = "block";
      alertBox.style.backgroundColor = "var(--apnaGreen)";
      alertBox.innerHTML = `
          <b>Thank you!</b> <br />
          We've reaceivd your message. Someone from our team will contact you
          soon.`;
      document.getElementById("formPoto").reset();
    };
    const notSent = () => {
      alertBox.style.display = "block";
      alertBox.style.backgroundColor = "red";
      alertBox.innerHTML = `
          <b>Try again!</b> <br />
          Something went wrong :(`;
    };
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success: sent(),
  });

  //Remove alert Box after 5 sec
    setInterval(function () {alertBox.style.display = "none"}, 6000);
  };
  return (
    <section className="d-flex mt60" id="getProto">
      {/* <div className='secProto1 container-fluid text-center'>
        <div className='r3 pb-0'>
          <div className='t1'>
            <span className='sqT'>&#9632;</span>
            Our Clients
            <span className='sqT'>&#9632;</span>
          </div>
        </div>
        <div className='clientGallery'>
        <img
            src='https://www.sibis.com.ua/wp-content/uploads/2017/06/Microsoft-logo.png'
            className='clientsImg'
            alt=''
          />
          <img
            src='https://www.google.com/logos/doodles/2018/president-george-h-w-bush-5637895883849728-2x.png'
            className='clientsImg'
            alt='google'
          />
          <img
            src='https://www.sibis.com.ua/wp-content/uploads/2017/06/Microsoft-logo.png'
            className='clientsImg'
            alt=''
          />
          <img
            src='https://www.sibis.com.ua/wp-content/uploads/2017/06/Microsoft-logo.png'
            className='clientsImg'
            alt=''
          />
          <img
            src='https://www.google.com/logos/doodles/2018/president-george-h-w-bush-5637895883849728-2x.png'
            className='clientsImg'
            alt='google'
          />
          <img
            src='https://www.sibis.com.ua/wp-content/uploads/2017/06/Microsoft-logo.png'
            className='clientsImg'
            alt=''
          />
          <img
            src='https://www.google.com/logos/doodles/2018/president-george-h-w-bush-5637895883849728-2x.png'
            className='clientsImg'
            alt='google'
          />
          <img
            src='https://www.sibis.com.ua/wp-content/uploads/2017/06/Microsoft-logo.png'
            className='clientsImg'
            alt=''
          />
          <img
            src='https://www.sibis.com.ua/wp-content/uploads/2017/06/Microsoft-logo.png'
            className='clientsImg'
            alt=''
          />
          <img
            src='https://www.google.com/logos/doodles/2018/president-george-h-w-bush-5637895883849728-2x.png'
            className='clientsImg'
            alt='google'
          />
          <img
            src='https://www.sibis.com.ua/wp-content/uploads/2017/06/Microsoft-logo.png'
            className='clientsImg'
            alt=''
          />
          <img
            src='https://www.sibis.com.ua/wp-content/uploads/2017/06/Microsoft-logo.png'
            className='clientsImg'
            alt=''
          />
          <img
            src='https://www.google.com/logos/doodles/2018/president-george-h-w-bush-5637895883849728-2x.png'
            className='clientsImg'
            alt='google'
          />
          <img
            src='https://www.sibis.com.ua/wp-content/uploads/2017/06/Microsoft-logo.png'
            className='clientsImg'
            alt=''
          />
          <img
            src='https://www.sibis.com.ua/wp-content/uploads/2017/06/Microsoft-logo.png'
            className='clientsImg'
            alt=''
          />
          <img
            src='https://www.google.com/logos/doodles/2018/president-george-h-w-bush-5637895883849728-2x.png'
            className='clientsImg'
            alt='google'
          />
          <img
            src='https://www.sibis.com.ua/wp-content/uploads/2017/06/Microsoft-logo.png'
            className='clientsImg'
            alt=''
          />
          <img
            src='https://www.sibis.com.ua/wp-content/uploads/2017/06/Microsoft-logo.png'
            className='clientsImg'
            alt=''
          />
        </div>
      </div> */}
      <div className="secProto2 getProto container-fluid text-center r3">
        <div className="t1">
          <span className="sqT">&#9632;</span>
          know more
          <span className="sqT">&#9632;</span>
        </div>
        <div className="t2 colorWhite">{props.head}</div>
        <div className="t3 colorWhite">{props.para}</div>
        <form onSubmit={sendEmail} id='formPoto' action="https://thepro.dev/mail.php">
          <div className="potoInput" role="alert" id="mailAlert">
          </div>
          <input
            className="potoInput"
            placeholder="Full Name"
            type="text"
            id="fullName"
            name="name"
            required
          />
          <input
            className="potoInput"
            placeholder="Phone Number"
            type="tel"
            id="phone"
            name="contact"
            pattern="^(\+\d{1,3}[- ]?)?\d{10}$"
          />
          <textarea
            className="potoInput"
            placeholder="Message"
            type="text"
            id="message"
            name="message"
          ></textarea>
          <button className="btn mt-2 colorWhite potoBtn" type="submit">
            SUBMIT
          </button>
        </form>
      </div>
    </section>
  );
}
getProto.defaultProps = {
  head: "Get a free prototype of your future website!",
  para:
    "Send your requirements for a website, we will research your specification and competitors, create a prototype of the website for free and sed a result to you!",
};
